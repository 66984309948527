<template>
    <div>
      <apexchart type="donut" :options="chartOptions" :series="series" height="200" 
      width="300" ></apexchart>
    </div>
  </template>
  
  <script>
  import VueApexCharts from "vue-apexcharts";
  
  export default {
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        series: [15000,  10251,20000], 
        chartOptions: {
          chart: {
                type: "donut",
                 fontFamily: "Lato, sans-serif", //Get rid of ApexChart default font family
            
          },
          labels: ["Poor", "Good", "Excellent"],
          colors: ["#C3002B", "#FF8E26", "#14CC26"], // Red, Orange, Green
          legend: {
              position: "right",
              margin: '20px',
          },
          dataLabels: {
            enabled: false, //  Removes numbers from the chart
            },
            stroke: {
          show: true,
            width: 5, 
  colors: "#fff",
   lineCap: "round", 
          
        },

          plotOptions: {
            pie: {
              donut: {
                size: "70%", 
                labels: {
                  show: true,
                   style: {
          borderRadius: "10px" 
        },
                  total: {
                    show: true,
                    label: "Total",
                    fontSize: "16px",
                    fontWeight: "bold",
                    color: "#333",
                    formatter: function (w) {
                      return w.globals.seriesTotals
                        .reduce((a, b) => a + b, 0)
                        .toLocaleString(); 
                    },
                  },
                },
              },
            },
          },
        },
      };
    },
  };
  </script>
  