<template>
  <div class="calendar-widget">
    <!-- Calendar header with month navigation -->
    <div class="calendar-header">
      <button class="nav-button" @click="previousMonth">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5025 16.5984L7.06914 11.1651C6.42747 10.5234 6.42747 9.47344 7.06914 8.83177L12.5025 3.39844" stroke="#555C74" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      </button>
      <div class="current-month">{{ currentMonthName }} {{ currentYear }}</div>
      <button class="nav-button" @click="nextMonth">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.42187 16.5984L12.8552 11.1651C13.4969 10.5234 13.4969 9.47344 12.8552 8.83177L7.42188 3.39844" stroke="#555C74" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

      </button>
    </div>
    
    <!-- Days of week header -->
    <div class="weekdays">
      <div class="weekday" v-for="day in weekdays" :key="day">{{ day }}</div>
    </div>
    
    <!-- Calendar days -->
    <div class="days-grid">
      <div 
        v-for="day in visibleDays" 
        :key="day.date" 
        class="day"
        :class="{ 'current-day': day.isCurrentDay, 'other-month': !day.isCurrentMonth }"
      >
        {{ day.dayNumber }}
        <div v-if="day.isCurrentDay" class="current-day-indicator"></div>
      </div>
    </div>
    
    <!-- Expand/collapse button -->
    <div class="expand-container">
      <button class="expand-button" @click="toggleExpanded">
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
          <polyline v-if="!expanded" points="6 9 12 15 18 9"></polyline>
          <polyline v-else points="18 15 12 9 6 15"></polyline>
        </svg>
      </button>
    </div>
    
    <!-- Appointment summary -->
    <div class="appointment-summary">
      <label class="checkbox-container">
       <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8 2V5" stroke="#555C74" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M16 2V5" stroke="#555C74" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M3.5 9.08984H20.5" stroke="#555C74" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M21 8.5V17C21 20 19.5 22 16 22H8C4.5 22 3 20 3 17V8.5C3 5.5 4.5 3.5 8 3.5H16C19.5 3.5 21 5.5 21 8.5Z" stroke="#555C74" stroke-width="0.7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.6976 13.6992H15.7066" stroke="#555C74" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M15.6976 16.6992H15.7066" stroke="#555C74" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9945 13.6992H12.0035" stroke="#555C74" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M11.9945 16.6992H12.0035" stroke="#555C74" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.29138 13.6992H8.30036" stroke="#555C74" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M8.29138 16.6992H8.30036" stroke="#555C74" stroke-width="0.7" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

        <span class="summary-text">
          You have {{ scheduledCount }} scheduled appointment{{ scheduledCount !== 1 ? 's' : '' }} and
          {{ newRequestCount }} new request{{ newRequestCount !== 1 ? 's' : '' }}.
        </span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    scheduledCount: {
      type: Number,
      default: 1
    },
    newRequestCount: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      currentDate: new Date(),
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      expanded: false,
      weekdays: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      visibleDaysCount: 7 // Initially show only first week
    }
  },
  computed: {
    currentMonthName() {
      return new Date(this.currentYear, this.currentMonth, 1).toLocaleString('default', { month: 'long' });
    },
    daysInMonth() {
      return new Date(this.currentYear, this.currentMonth + 1, 0).getDate();
    },
    firstDayOfMonth() {
      let day = new Date(this.currentYear, this.currentMonth, 1).getDay();
      // Adjust for Monday as first day of week (0 = Monday, 6 = Sunday)
      return day === 0 ? 6 : day - 1;
    },
    allDays() {
      const days = [];
      const today = new Date();
      const isCurrentMonth = today.getMonth() === this.currentMonth && today.getFullYear() === this.currentYear;
      const currentDay = today.getDate();
      
      // Previous month days to fill the first week
      const prevMonth = this.currentMonth === 0 ? 11 : this.currentMonth - 1;
      const prevMonthYear = this.currentMonth === 0 ? this.currentYear - 1 : this.currentYear;
      const daysInPrevMonth = new Date(prevMonthYear, prevMonth + 1, 0).getDate();
      
      for (let i = 0; i < this.firstDayOfMonth; i++) {
        const dayNumber = daysInPrevMonth - this.firstDayOfMonth + i + 1;
        days.push({
          date: `${prevMonthYear}-${prevMonth + 1}-${dayNumber}`,
          dayNumber,
          isCurrentMonth: false,
          isCurrentDay: false
        });
      }
      
      // Current month days
      for (let i = 1; i <= this.daysInMonth; i++) {
        days.push({
          date: `${this.currentYear}-${this.currentMonth + 1}-${i}`,
          dayNumber: i,
          isCurrentMonth: true,
          isCurrentDay: isCurrentMonth && i === currentDay
        });
      }
      
      // Next month days to fill the last week
      const nextMonth = this.currentMonth === 11 ? 0 : this.currentMonth + 1;
      const nextMonthYear = this.currentMonth === 11 ? this.currentYear + 1 : this.currentYear;
      const totalCells = Math.ceil((this.firstDayOfMonth + this.daysInMonth) / 7) * 7;
      
      for (let i = 1; i <= totalCells - this.firstDayOfMonth - this.daysInMonth; i++) {
        days.push({
          date: `${nextMonthYear}-${nextMonth + 1}-${i}`,
          dayNumber: i,
          isCurrentMonth: false,
          isCurrentDay: false
        });
      }
      
      return days;
    },
    visibleDays() {
      return this.expanded ? this.allDays : this.allDays.slice(0, this.visibleDaysCount);
    }
  },
  methods: {
    previousMonth() {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.currentYear--;
      } else {
        this.currentMonth--;
      }
    },
    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.currentYear++;
      } else {
        this.currentMonth++;
      }
    },
    toggleExpanded() {
      this.expanded = !this.expanded;
    }
  }
}
</script>

<style scoped>
.calendar-widget {
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.current-month {
  font-weight: 500;
  font-size: 16px;
}

.nav-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #555C74;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.nav-button:hover {
  background-color: #f3f4f6;
}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  margin-bottom: 8px;
}

.weekday {
  font-size: 14px;
  color: #555C74;
  font-weight: 500;
}

.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

.day {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 14px;
  border-radius: 4px;
}

.day:hover {
  cursor: pointer;
}

.current-day {
  font-weight: 500;
}

.current-day-indicator {
  position: absolute;
  bottom: 2px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #1d4ed8;
}

.other-month {
  color: #d1d5db;
}

.expand-container {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 4px;
}

.expand-button:hover {
  background-color: #f3f4f6;
}

.appointment-summary {
  margin-top: 8px;
  padding-top: 16px;
  border-top: 1px solid #e5e7eb;
}

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  color: #555C74;
}




.summary-text {
  line-height: 1.5;
}
</style>

