<template>
  <div class="tabs-container">
    <!-- Tab headers -->
    <div class="tabs">
      <div
        class="tab"
        :class="{ active: activeTab === 'scheduled' }"
        @click="activeTab = 'scheduled'"
      >
        Scheduled <span class="count">{{ scheduledRequests.length }}</span>
      </div>
      <div
        class="tab"
        :class="{ active: activeTab === 'new' }"
        @click="activeTab = 'new'"
      >
        New request <span class="count">{{ newRequests.length }}</span>
      </div>
    </div>

    <!-- Tab content -->
    <div class="tab-content">
      <!-- Scheduled tab content -->
      <div v-if="activeTab === 'scheduled'">
        <div v-if="scheduledRequests.length === 0" class="empty-state">
          <div class="calendar-icon">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M44.4795 12.0419C44.5195 12.5219 44.4795 13.0219 44.3595 13.5219L37.6195 40.5818C37.1395 42.6018 35.3395 44.0018 33.2595 44.0018H6.97948C3.95948 44.0018 1.81951 41.0418 2.69951 38.1418L11.1195 11.0818C11.6995 9.20179 13.4395 7.92188 15.3995 7.92188H39.9995C41.9195 7.92188 43.4795 9.06187 44.1395 10.6619C44.3395 11.0819 44.4395 11.5619 44.4795 12.0419Z"
                fill="#555C74"
              />
              <path
                d="M46.4817 39.24C46.6817 41.82 44.6417 44 42.0617 44H33.2617C35.3417 44 37.1417 42.6 37.6217 40.58L44.3617 13.52C44.4817 13.02 44.5217 12.52 44.4817 12.04L44.5017 12L46.4817 39.24Z"
                fill="#555C74"
              />
              <path
                d="M19.8595 14.2588C19.7395 14.2588 19.6195 14.2388 19.4995 14.2188C18.6995 14.0188 18.1996 13.2188 18.3996 12.3988L20.4795 3.7588C20.6795 2.9588 21.4795 2.47882 22.2995 2.65882C23.0995 2.85882 23.5996 3.65877 23.3996 4.47877L21.3196 13.1188C21.1596 13.7988 20.5395 14.2588 19.8595 14.2588Z"
                fill="#555C74"
              />
              <path
                d="M33.2589 14.2796C33.1589 14.2796 33.0389 14.2796 32.9389 14.2396C32.1389 14.0596 31.6189 13.2595 31.7789 12.4595L33.6589 3.7796C33.8389 2.9596 34.6389 2.45957 35.4389 2.61957C36.2389 2.79957 36.7589 3.5996 36.5989 4.3996L34.7189 13.0795C34.5789 13.7995 33.9589 14.2796 33.2589 14.2796Z"
                fill="#555C74"
              />
              <path
                d="M31.8984 25.5H15.8984C15.0784 25.5 14.3984 24.82 14.3984 24C14.3984 23.18 15.0784 22.5 15.8984 22.5H31.8984C32.7184 22.5 33.3984 23.18 33.3984 24C33.3984 24.82 32.7184 25.5 31.8984 25.5Z"
                fill="#555C74"
              />
              <path
                d="M29.8984 33.5H13.8984C13.0784 33.5 12.3984 32.82 12.3984 32C12.3984 31.18 13.0784 30.5 13.8984 30.5H29.8984C30.7184 30.5 31.3984 31.18 31.3984 32C31.3984 32.82 30.7184 33.5 29.8984 33.5Z"
                fill="#555C74"
              />
            </svg>
          </div>
          <h3>No scheduled viewing</h3>
          <p>
            Confirmed appointments will be displayed here. Keep an eye out for
            new appointments.
          </p>
        </div>
        <div v-else>
          <div
            v-for="(request, index) in scheduledRequests"
            :key="'scheduled-' + index"
            class="request-card"
          >
            <div class="request-header">
              <div class="request-info">
                <div class="request-title">
                  Viewing request from {{ request.clientName }}
                </div>
                <div class="request-date">
                  {{ request.date }} | {{ request.time }} |
                  {{ request.location }}
                </div>
              </div>
              <button
                class="details-button"
                @click="toggleScheduledDetails(index)"
              >
                Details

                <svg
                  v-if="!scheduledExpandedStates[index]"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5984 7.45703L11.1651 12.8904C10.5234 13.532 9.47344 13.532 8.83177 12.8904L3.39844 7.45703"
                    stroke="#535862"
                    stroke-width="1.6"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <svg
                  v-else
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5984 12.5435L11.1651 7.11016C10.5234 6.46849 9.47344 6.46849 8.83177 7.11016L3.39844 12.5435"
                    stroke="#535862"
                    stroke-width="1.6"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>

            <div v-if="scheduledExpandedStates[index]" class="request-details">
              <div class="details-grid">
                <div class="details-row">
                  <div class="details-cell">
                    <div class="details-label">Property name</div>
                    <div class="details-value">{{ request.propertyName }}</div>
                  </div>
                  <div class="details-cell">
                    <div class="details-label">Location/address</div>
                    <div class="details-value">{{ request.address }}</div>
                  </div>
                </div>

                <div class="details-row">
                  <div class="details-cell">
                    <div class="details-label">PIN</div>
                    <div class="details-value">{{ request.pin }}</div>
                  </div>
                  <div class="details-cell">
                    <div class="details-label">Client name</div>
                    <div class="details-value">{{ request.clientName }}</div>
                  </div>
                </div>

                <div class="details-row">
                  <div class="details-cell">
                    <div class="details-label">Date</div>
                    <div class="details-value">{{ request.fullDate }}</div>
                  </div>
                  <div class="details-cell">
                    <div class="details-label">Time</div>
                    <div class="details-value">{{ request.time }}</div>
                  </div>
                </div>

                <div class="details-row">
                  <div class="details-cell">
                    <div class="details-label">Phone number</div>
                    <div class="details-value">{{ request.phone }}</div>
                  </div>
                  <div class="details-cell">
                    <div class="details-label">Email address</div>
                    <div class="details-value">{{ request.email }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="request-actions">
              <button
                class="btn btn-secondary"
                data-toggle="modal"
                data-target="#rescheduleModal"
                @click="rescheduleRequest(request, 'scheduled', index)"
              >
                Reschedule
              </button>
              <button
                data-toggle="modal"
                data-target="#cancelModal"
                class="btn btn-secondary cancel"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- New requests tab content -->
      <div v-if="activeTab === 'new'">
        <div v-if="newRequests.length === 0" class="empty-state">
          <div class="calendar-icon">
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M44.4795 12.0419C44.5195 12.5219 44.4795 13.0219 44.3595 13.5219L37.6195 40.5818C37.1395 42.6018 35.3395 44.0018 33.2595 44.0018H6.97948C3.95948 44.0018 1.81951 41.0418 2.69951 38.1418L11.1195 11.0818C11.6995 9.20179 13.4395 7.92188 15.3995 7.92188H39.9995C41.9195 7.92188 43.4795 9.06187 44.1395 10.6619C44.3395 11.0819 44.4395 11.5619 44.4795 12.0419Z"
                fill="#555C74"
              />
              <path
                d="M46.4817 39.24C46.6817 41.82 44.6417 44 42.0617 44H33.2617C35.3417 44 37.1417 42.6 37.6217 40.58L44.3617 13.52C44.4817 13.02 44.5217 12.52 44.4817 12.04L44.5017 12L46.4817 39.24Z"
                fill="#555C74"
              />
              <path
                d="M19.8595 14.2588C19.7395 14.2588 19.6195 14.2388 19.4995 14.2188C18.6995 14.0188 18.1996 13.2188 18.3996 12.3988L20.4795 3.7588C20.6795 2.9588 21.4795 2.47882 22.2995 2.65882C23.0995 2.85882 23.5996 3.65877 23.3996 4.47877L21.3196 13.1188C21.1596 13.7988 20.5395 14.2588 19.8595 14.2588Z"
                fill="#555C74"
              />
              <path
                d="M33.2589 14.2796C33.1589 14.2796 33.0389 14.2796 32.9389 14.2396C32.1389 14.0596 31.6189 13.2595 31.7789 12.4595L33.6589 3.7796C33.8389 2.9596 34.6389 2.45957 35.4389 2.61957C36.2389 2.79957 36.7589 3.5996 36.5989 4.3996L34.7189 13.0795C34.5789 13.7995 33.9589 14.2796 33.2589 14.2796Z"
                fill="#555C74"
              />
              <path
                d="M31.8984 25.5H15.8984C15.0784 25.5 14.3984 24.82 14.3984 24C14.3984 23.18 15.0784 22.5 15.8984 22.5H31.8984C32.7184 22.5 33.3984 23.18 33.3984 24C33.3984 24.82 32.7184 25.5 31.8984 25.5Z"
                fill="#555C74"
              />
              <path
                d="M29.8984 33.5H13.8984C13.0784 33.5 12.3984 32.82 12.3984 32C12.3984 31.18 13.0784 30.5 13.8984 30.5H29.8984C30.7184 30.5 31.3984 31.18 31.3984 32C31.3984 32.82 30.7184 33.5 29.8984 33.5Z"
                fill="#555C74"
              />
            </svg>
          </div>
          <h3>No new requests</h3>
          <p>New viewing requests will appear here when clients make them.</p>
        </div>
        <div v-else>
          <div
            v-for="(request, index) in newRequests"
            :key="'new-' + index"
            class="request-card"
          >
            <div class="request-header">
              <div class="request-info">
                <div class="request-title">
                  Viewing request from {{ request.clientName }}
                </div>
                <div class="request-date">
                  {{ request.date }} | {{ request.time }} |
                  {{ request.location }}
                </div>
              </div>
              <button class="details-button" @click="toggleNewDetails(index)">
                Details
                <svg
                  v-if="!newExpandedStates[index]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="18 15 12 9 6 15"></polyline>
                </svg>
              </button>
            </div>

            <div v-if="newExpandedStates[index]" class="request-details">
              <div class="details-grid">
                <div class="details-row">
                  <div class="details-cell">
                    <div class="details-label">Property name</div>
                    <div class="details-value">{{ request.propertyName }}</div>
                  </div>
                  <div class="details-cell">
                    <div class="details-label">Location/address</div>
                    <div class="details-value">{{ request.address }}</div>
                  </div>
                </div>

                <div class="details-row">
                  <div class="details-cell">
                    <div class="details-label">PIN</div>
                    <div class="details-value">{{ request.pin }}</div>
                  </div>
                  <div class="details-cell">
                    <div class="details-label">Client name</div>
                    <div class="details-value">{{ request.clientName }}</div>
                  </div>
                </div>

                <div class="details-row">
                  <div class="details-cell">
                    <div class="details-label">Date</div>
                    <div class="details-value">{{ request.fullDate }}</div>
                  </div>
                  <div class="details-cell">
                    <div class="details-label">Time</div>
                    <div class="details-value">{{ request.time }}</div>
                  </div>
                </div>

                <div class="details-row">
                  <div class="details-cell">
                    <div class="details-label">Phone number</div>
                    <div class="details-value">{{ request.phone }}</div>
                  </div>
                  <div class="details-cell">
                    <div class="details-label">Email address</div>
                    <div class="details-value">{{ request.email }}</div>
                  </div>
                </div>
              </div>
            </div>

            <div class="request-actions">
              <button class="btn btn-primary" @click="acceptRequest(index)">
                Accept
              </button>
              <button
                class="btn btn-secondary"
                @click="rescheduleRequest(request, 'new', index)"
              >
                Reschedule
              </button>
              <button class="btn btn-secondary" @click="declineRequest(index)">
                Decline
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- RESCHEDULE MODAL -->
    <div
      class="modal fade"
      id="rescheduleModal"
      tabindex="-1"
      aria-labelledby="rescheduleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="rescheduleModalLabel">Edit details</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <p class="modal-description">
              Propose a new date or time for this viewing with John Doe.
            </p>

            <div class="example-schedule">
              <div class="example-date">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.33398 1.33203V3.33203"
                    stroke="#555C74"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.666 1.33203V3.33203"
                    stroke="#555C74"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.33398 6.05859H13.6673"
                    stroke="#555C74"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 5.66536V11.332C14 13.332 13 14.6654 10.6667 14.6654H5.33333C3 14.6654 2 13.332 2 11.332V5.66536C2 3.66536 3 2.33203 5.33333 2.33203H10.6667C13 2.33203 14 3.66536 14 5.66536Z"
                    stroke="#555C74"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4644 9.13411H10.4704"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4644 11.1341H10.4704"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.99569 9.13411H8.00168"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.99569 11.1341H8.00168"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.52694 9.13411H5.53293"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.52694 11.1341H5.53293"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Sept 3
              </div>
              <div class="example-time">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6673 7.9987C14.6673 11.6787 11.6807 14.6654 8.00065 14.6654C4.32065 14.6654 1.33398 11.6787 1.33398 7.9987C1.33398 4.3187 4.32065 1.33203 8.00065 1.33203C11.6807 1.33203 14.6673 4.3187 14.6673 7.9987Z"
                    stroke="#555C74"
                    stroke-width="0.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4759 10.1211L8.40919 8.88781C8.04919 8.67448 7.75586 8.16115 7.75586 7.74115V5.00781"
                    stroke="#555C74"
                    stroke-width="0.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                8:00 am
              </div>
            </div>

            <div class="divider"></div>

            <div class="schedule-details">
              <h3 class="section-title">New schedule details</h3>

              <div class="form-group">
                <label for="date">Date</label>
                <div class="input-with-icon">
                  <input type="text" id="date" placeholder="Select" readonly />
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.33398 1.33203V3.33203"
                      stroke="#555C74"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.666 1.33203V3.33203"
                      stroke="#555C74"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.33398 6.05859H13.6673"
                      stroke="#555C74"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14 5.66536V11.332C14 13.332 13 14.6654 10.6667 14.6654H5.33333C3 14.6654 2 13.332 2 11.332V5.66536C2 3.66536 3 2.33203 5.33333 2.33203H10.6667C13 2.33203 14 3.66536 14 5.66536Z"
                      stroke="#555C74"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.4644 9.13411H10.4704"
                      stroke="#555C74"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.4644 11.1341H10.4704"
                      stroke="#555C74"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.99569 9.13411H8.00168"
                      stroke="#555C74"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.99569 11.1341H8.00168"
                      stroke="#555C74"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.52694 9.13411H5.53293"
                      stroke="#555C74"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.52694 11.1341H5.53293"
                      stroke="#555C74"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>

              <div class="form-group">
                <label for="time">Select time</label>
                <div class="time-select-container">
                  <input
                    type="text"
                    id="time"
                    v-model="selectedTime"
                    readonly
                    @click="showTimeDropdown = !showTimeDropdown"
                  />
                  <div v-if="showTimeDropdown" class="time-dropdown">
                    <div
                      v-for="time in timeOptions"
                      :key="time"
                      class="time-option"
                      @click="selectTime(time)"
                    >
                      {{ time }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button class="reschedule-button" @click="acceptRequest(index)">Reschedule</button>
          </div>
        </div>
      </div>
    </div>

    <!-- CANCEL/DECLINE MODAL -->
    <div
      class="modal fade"
      id="cancelModal"
      tabindex="-1"
      aria-labelledby="cancelModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="cancelModalLabel">
              Cancel scheduled request
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <p class="modal-description">Viewing request from John Doe</p>

            <div class="example-schedule">
              <div class="example-date">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.33398 1.33203V3.33203"
                    stroke="#555C74"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.666 1.33203V3.33203"
                    stroke="#555C74"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.33398 6.05859H13.6673"
                    stroke="#555C74"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14 5.66536V11.332C14 13.332 13 14.6654 10.6667 14.6654H5.33333C3 14.6654 2 13.332 2 11.332V5.66536C2 3.66536 3 2.33203 5.33333 2.33203H10.6667C13 2.33203 14 3.66536 14 5.66536Z"
                    stroke="#555C74"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4644 9.13411H10.4704"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4644 11.1341H10.4704"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.99569 9.13411H8.00168"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.99569 11.1341H8.00168"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.52694 9.13411H5.53293"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M5.52694 11.1341H5.53293"
                    stroke="#555C74"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                Sept 3
              </div>
              <div class="example-time">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6673 7.9987C14.6673 11.6787 11.6807 14.6654 8.00065 14.6654C4.32065 14.6654 1.33398 11.6787 1.33398 7.9987C1.33398 4.3187 4.32065 1.33203 8.00065 1.33203C11.6807 1.33203 14.6673 4.3187 14.6673 7.9987Z"
                    stroke="#555C74"
                    stroke-width="0.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M10.4759 10.1211L8.40919 8.88781C8.04919 8.67448 7.75586 8.16115 7.75586 7.74115V5.00781"
                    stroke="#555C74"
                    stroke-width="0.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                8:00 am
              </div>
            </div>


            <div class="cancellation-reason">
              <p class="reason-title">
                Why are you unable to proceed with this request?
                <span class="required">*</span>
              </p>

              <div class="reason-options">
                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="unavailable"
                    v-model="selectedReason"
                  />
                  <span class="radio-label"
                    >Property is no longer available</span
                  >
                </label>

                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="owner"
                    v-model="selectedReason"
                  />
                  <span class="radio-label">Property owner is unavailable</span>
                </label>

                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="double-booking"
                    v-model="selectedReason"
                  />
                  <span class="radio-label">Double booking conflict</span>
                </label>

                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="emergency"
                    v-model="selectedReason"
                  />
                  <span class="radio-label">Unexpected personal emergency</span>
                </label>

                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="client-request"
                    v-model="selectedReason"
                  />
                  <span class="radio-label">Client requested cancellation</span>
                </label>

                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="weather"
                    v-model="selectedReason"
                  />
                  <span class="radio-label">Poor weather conditions</span>
                </label>

                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="security"
                    v-model="selectedReason"
                  />
                  <span class="radio-label">Security concerns in the area</span>
                </label>

                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="technical"
                    v-model="selectedReason"
                  />
                  <span class="radio-label"
                    >Technical issues with the property (e.g., maintenance
                    work)</span
                  >
                </label>

                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="scheduling"
                    v-model="selectedReason"
                  />
                  <span class="radio-label">Scheduling conflict</span>
                </label>

                <label class="reason-option">
                  <input
                    type="radio"
                    name="reason"
                    value="other"
                    v-model="selectedReason"
                  />
                  <span class="radio-label">Other</span>
                </label>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button
              class="cancel-button"
              :class="{ 'cancel-button-active': selectedReason }"
              :disabled="!selectedReason"
@click="cancelRequest(index)"            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeTab: "new", // Default tab is "New request"
      newExpandedStates: [false], 
      scheduledExpandedStates: [false],
        selectedReason: null,
      scheduledRequests: [ ],
      selectedTime: "9:00",
      showTimeDropdown: false,
      timeOptions: [
        "09:00 AM",
        "10:00 AM",
        "11:00 AM",
        "12:00 PM",
        "01:00 PM",
        "02:00 PM",
        "03:00 PM",
        "04:00 PM",
        "05:00 PM",
      ],
      newRequests: [
        {
          id: 1,
          clientName: "John Doe",
          date: "Mar 16",
          fullDate: "March 16, 2025",
          time: "3:00 PM",
          location: "Lekki, Lagos",
          propertyName: "Furnished smart home",
          address: "15 Banana island road, Lagos",
          pin: "2323123234",
          phone: "08123243234",
          email: "johndoe@example.com",
        },
        {
          id: 2,
          clientName: "John Doe",
          date: "Apr 15",
          fullDate: "April 15, 2025",
          time: "3:00 PM",
          location: "Lekki, Lagos",
          propertyName: "Furnished smart home",
          address: "15 Banana island road, Lagos",
          pin: "2323123234",
          phone: "08123243234",
          email: "johndoe@example.com",
        },
      ],
    };
  },
  methods: {
    selectTime(time) {
      this.selectedTime = time.split("  ");
      this.showTimeDropdown = false;
    },

    toggleNewDetails(index) {
      this.$set(this.newExpandedStates, index, !this.newExpandedStates[index]);
    },
    toggleScheduledDetails(index) {
      this.$set(
        this.scheduledExpandedStates,
        index,
        !this.scheduledExpandedStates[index]
      );
    },
    acceptRequest(index) {
      const request = this.newRequests[index];

      const scheduledRequest = { ...request };

      // Add to scheduled requests
      this.scheduledRequests.push(scheduledRequest);

      // Remove from new requests
      this.newRequests.splice(index, 1);

      // Update expanded states arrays
      this.newExpandedStates.splice(index, 1);
      this.scheduledExpandedStates.push(false);

      // Switch to the scheduled tab to show the newly scheduled request
      this.activeTab = "scheduled";
    },

    declineRequest(index) {
      // Remove the request from newRequests
      this.newRequests.splice(index, 1);
      this.newExpandedStates.splice(index, 1);
    },
    cancelRequest(index) {
      // Remove the request from scheduledRequests
      this.scheduledRequests.splice(index, 1);
      this.scheduledExpandedStates.splice(index, 1);
    },

    confirmCancel() {
      if (this.selectedReason) {
        this.$emit('confirm', this.selectedReason);
      }
    },
  },
  mounted() {
    // For demonstration, add some sample scheduled requests
    this.scheduledRequests = [
      {
        id: 3,
        clientName: "John Doe",
        date: "Mar 5",
        fullDate: "Mar 5, 2025",
        time: "3:00 PM",
        location: "Lekki, Lagos",
        propertyName: "Furnished smart home",
        address: "15 Banana island road, Lagos",
        pin: "2323123234",
        phone: "08123243234",
        email: "johndoe@example.com",
      },
      {
        id: 4,
        clientName: "John Doe",
        date: "Jun 5",
        fullDate: "Mar 5, 2025",
        time: "3:00 PM",
        location: "Lekki, Lagos",
        propertyName: "Furnished smart home",
        address: "15 Banana island road, Lagos",
        pin: "2323123234",
        phone: "08123243234",
        email: "johndoe@example.com",
      },
    ];
  },
};
</script>

<style scoped>
/* Tab container */
.tabs-container {
  width: 100%;
  margin: 20px auto 0;
  background-color: #fff;
  border-radius: 16px;
}

/* Tabs styling */
.tabs {
  display: flex;
  border-bottom: 1px solid #e5e7eb;
}

.tab {
  padding: 16px;
  cursor: pointer;
  position: relative;
  color: #555c74;
  font-weight: 600;
  font-size: 16px;
  transition: color 0.2s ease;
}

.tab:hover {
  color: #0033ea;
}

.tab.active {
  color: #0033ea;
}

.tab.active::after {
  content: "";
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #0033ea;
}

.count {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  background-color: #fafafa;
  color: #555c74;
  border-radius: 9999px;
  padding: 2px 8px;
  font-size: 14px;
  border: 1px solid #e9eaeb;
  min-width: 24px;
}

/* Tab content */
.tab-content {
  padding: 16px;
}

/* Empty state */
.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 48px 24px;
  text-align: center;
  background-color: #fafafa;
  border-radius: 8px;
}

.calendar-icon {
  margin-bottom: 16px;
  color: #9ca3af;
}

.empty-state h3 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #2b3352;
}

.empty-state p {
  color: #555c74;
  font-size: 14px;
  font-weight: 400;
  max-width: 400px;
}

/* Request card */
.request-card {
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  margin-bottom: 16px;
  overflow: hidden;
}

.request-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
}

.request-info {
  flex: 1;
}

.request-title {
  font-size: 14px;
  font-weight: 500;
  color: #2b3352;
  margin-bottom: 5px;
}

.request-date {
  color: #555c74;
  font-weight: 400;
  font-size: 14px;
}

.details-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #6b7280;
  font-size: 14px;
}

.details-button:hover {
  color: #1d4ed8;
}

/* Request details */
.request-details {
  padding: 0 16px 16px;
  border-bottom: 1px solid #e5e7eb;
}

.details-grid {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.details-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.details-cell {
  flex: 1;
  min-width: 200px;
}

.details-label {
  color: #555c74;
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 400;
}

.details-value {
  font-weight: 600;
  font-size: 14px;
  color: #2b3352;
}

/* Request actions */
.request-actions {
  display: flex;
  gap: 8px;
  padding: 16px;
}

.btn {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid transparent;
  font-size: 14px;
  transition: all 0.2s ease;
}

.btn-primary {
  background-color: #0033ea;
  border-radius: 8px;
  color: white;
}

.btn-secondary {
  background-color: white;
  border-color: #d5d7da;
  color: #374151;
  border-radius: 8px;
}
.btn-secondary.cancel {
  border: none;
}

/* Responsive styles */
@media (max-width: 640px) {
  .tabs {
    padding: 0 8px;
  }

  .tab {
    padding: 12px 8px;
    font-size: 14px;
  }

  .details-row {
    flex-direction: column;
    gap: 12px;
  }

  .details-cell {
    min-width: 100%;
  }

  .request-actions {
    flex-wrap: wrap;
  }

  .btn {
    flex: 1;
    min-width: 80px;
    text-align: center;
  }
}

/* RESCHEDULE MODAL */

.modal-body {
  padding: 0 24px;
}

.modal-description {
  margin-top: 0;
  margin-bottom: 16px;
  color: #555C74;
  line-height:25px;
  font-size: 14px;
}

.example-schedule {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.example-date,
.example-time {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #555C74;
  font-size: 14px;
}
.time-option {
  cursor: pointer;
  padding: 5px 10px;
}
.divider {
  height: 1px;
  background-color: #E2E8F0;
  margin: 16px 0;
}

.section-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
  color: #555C74;
}

.form-group {
  margin-bottom: 16px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight:600;
  color: #555C74;
}

.input-with-icon {
  position: relative;
}

.input-with-icon input,
input[type="text"] {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #D5D7DA;
  border-radius: 8px;
  font-size: 14px;
  color: #555C74;
}

.input-with-icon svg {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #555C74;
}

.modal-footer {
  padding: 16px 24px 24px;
}

.reschedule-button {
  background-color: #0033EA;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
}

/* CANCEL MODAL */

.cancellation-reason {
  margin-bottom: 16px;
}

.reason-title {
  margin: 0 0 16px 0;
  font-size: 16px;
    color: #2B3352;
  font-weight: 600;
}

.required {
  color: #ef4444;
  margin-left: 2px;
}

.reason-options {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
}

.reason-option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.reason-option input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 2px solid #D5D7DA;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  margin-right: 10px;
  transform: translateY(4px);
  position: relative;
}

.reason-option input[type="radio"]:checked {
  border-color: #0033EA;
 
}

.reason-option input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px;
  height: 10px;
  background-color: #0033EA;
  border-radius: 50%;
}

.radio-label {
  font-size: 14px;
  color: #555C74;
  font-weight: 400;
}
.cancel-button {
  background-color: #F5F5F5;
  color: #555C7499;
  border: 1px solid #E9EAEB;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
}

.cancel-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.cancel-button-active {
  background-color: #D92D20;
  border-color: #D92D20;
  color: white;
}
</style>
