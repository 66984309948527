/* eslint-disable */
<template>
  <div class="profile-container">
    <div class="profile-overview">
      <div class="profile-header">
        <h2>Profile Overview</h2>
        <button class="btn" data-toggle="modal" data-target="#editFormModal">
          Edit profile
        </button>
      </div>

      <!-- STATS -->
      <div class="stats">
        <div class="main-stats">
          <h4>Total earnings</h4>
          <div>₦32,984</div>
        </div>

        <div>
          <div class="main-stats">
            <h4>Impressions</h4>
            <div>40</div>
          </div>
        </div>
      </div>

      <!-- APPOINTMENT/SCHEDULES -->
      <div class="appointments-container">
        <TabComponent />
      </div>
    </div>
    <!-- ASIDE: PROFILE INFO (All the EDIT buttons here triggers the Modal) -->
    <div class="profile-info-container">
      <div class="profile-info">
        <div class="profile-img">
          <img src="@/assets/placeholders-images/user-demo.png" alt="" />
        </div>

        <div class="user-details">
          <h4>
            {{ user.name }}
            <img src="@/assets/placeholders-images/online-indicator.png" alt="">
          </h4>

          <p class="user-location">{{ user.state }}</p>
        </div>
      </div>

      <!-- Calendar Section -->
      <div class="calendar-section">
        <AgentProfileCalendar
          :scheduled-count="scheduledRequests.length"
          :new-request-count="newRequests.length"
        />
      </div>

      <!-- REVIEWS CHART -->

      <div class="chart-container">
        <h2 class="title">Customer's Satisfaction</h2>
        <div class="chart-wrapper">
          <ReviewChart />
        </div>
      </div>


       
    </div>
  </div>
</template>

<script>
import TabComponent from "./AgentProfileTabs.vue";
import AgentProfileCalendar from "./AgentProfileCalendar.vue";
import ReviewChart from "./ReviewChart.vue";
export default {
  components: {
    TabComponent,
    AgentProfileCalendar,
    ReviewChart,
    
  },
  data() {
    return {
      daysOfWeek: ["M", "T", "W", "T", "F", "S", "S"],
      days: [12, 13, 14, 15, 16, 17, 18],
      today: 16,
      currentMonthYear: "March 2025",
       showRescheduleModal: false,
      user: {
        name: "Tosin Kehinde",
        state: "Federal Capital Territory",
      },
      scheduledRequests: [
        {
          id: 3,
          clientName: "John Doe",
          date: "Mar 5",
          fullDate: "Mar 5, 2025",
          time: "3:00 PM",
          location: "Lekki, Lagos",
          propertyName: "Furnished smart home",
          address: "15 Banana island road, Lagos",
          pin: "2323123234",
          phone: "08123243234",
          email: "johndoe@example.com",
        },
        {
          id: 4,
          clientName: "John Doe",
          date: "Jun 5",
          fullDate: "Mar 5, 2025",
          time: "3:00 PM",
          location: "Lekki, Lagos",
          propertyName: "Furnished smart home",
          address: "15 Banana island road, Lagos",
          pin: "2323123234",
          phone: "08123243234",
          email: "johndoe@example.com",
        },
      ],
      newRequests: [
        {
          id: 1,
          clientName: "John Doe",
          date: "Mar 16",
          fullDate: "March 16, 2025",
          time: "3:00 PM",
          location: "Lekki, Lagos",
          propertyName: "Furnished smart home",
          address: "15 Banana island road, Lagos",
          pin: "2323123234",
          phone: "08123243234",
          email: "johndoe@example.com",
        },
        {
          id: 2,
          clientName: "John Doe",
          date: "Apr 15",
          fullDate: "April 15, 2025",
          time: "3:00 PM",
          location: "Lekki, Lagos",
          propertyName: "Furnished smart home",
          address: "15 Banana island road, Lagos",
          pin: "2323123234",
          phone: "08123243234",
          email: "johndoe@example.com",
        },
      ],
    };
  },
  methods: {
    toggleDetails(index) {
      this.newRequests[index].showDetails =
        !this.newRequests[index].showDetails;
      const username = this.$store.getters.getUser.id;

      console.log("Username", username);
    },
  },
};
</script>

<style scoped>
/* EVERYTHING IN THIS COMPONENT SHOULD USE THE LATO FONT */
* {
  font-family: "Lato", sans-serif;
}

/* REUSEABLE CLASSES FOR THIS COMPONENT*/
.cursor-pointer {
  cursor: pointer;
}

.no-bg {
  background: none !important;
}
.font-400 {
  font-weight: 400;
  font-size: 14px;
  color: #555c74;
}
.font-600 {
  font-weight: 600;
  font-size: 16px;
}
.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.space-between {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.text-blue {
  color: #0033ea;
}
.text-green {
  color: #2bc300;
}

/* ================ MAIN STYLES ===============*/
.profile-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 24px;
  gap: 20px !important;
  padding: 0 20px;
}

.profile-container .profile-overview {
  grid-column: span 2;
  margin-right: 2rem;
}

.profile-container .profile-info-container {
  background: #fff;
  padding: 32px 24px;
  border-radius: 8px;
  line-height: 25px;
}

.profile-container .profile-info-container .profile-info {
  display: flex;
  gap: 12px;
  border-bottom: 1px solid #f0f2f5;
  padding: 16px 0;
}

.profile-container .profile-info-container .profile-info,
.user-details h1 {
  color: #2b3352;
  font-size: 16px;
  font-weight: 600;
}

.profile-container .profile-info-container .profile-info,
.user-location {
  font-size: 14px;
  font-weight: 400;
  color: #2b3352;
}
.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
}

.profile-header .btn {
  background: hsla(227, 100%, 46%, 1);
  color: #fff;
}

.profile-info-container .phone-info {
  gap: 12px;
  padding: 24px 0;
  justify-content: space-between;
  border-bottom: 1px solid #f0f2f5;
}

.profile-info-container .phone-info,
.phone-number {
  font-weight: 500;
  font-size: 14px;
}



/*============  STATS ============= */

.profile-overview > h2 {
  margin-bottom: 20px;
  font-size: 32px;
  font-weight: 700;
}
.stats {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  justify-content: space-between;
}

.stats .main-stats {
  background: #fff;
  display: flex;

  align-items: flex-start;
  justify-content: flex-start;
  text-align: left !important;
  padding: 32px 22px;
  border-radius: 16px;
  border: 1px solid hsla(210, 5%, 92%, 1);
  box-shadow: 0px 1px 2px hsla(218, 29%, 5%, 0.05);
}

.stats h4 {
  font-size: 14px;
  color: hsla(226, 15%, 39%, 1);
}

.stats div {
  font-size: 30px;
  color: hsla(228, 31%, 25%, 1);
  font-weight: 600;
}

.main-stats {
  display: flex;
  flex-direction: column;
}

/*============  CHARTS ============= */
.chart-container {
  width: 100%;
  margin: 40px auto;
}
.chart-container .title {
  font-size: 18px;
  color: #4a4a4a;
  margin-bottom: 20px;
}

.input-wrapper {
  position: relative;
  display: flex;
}

input,
select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  font-size: 14px;
  color: #111827;
  background: #ffffff;
}

input:focus,
select:focus {
  outline: none;
}
/* CALENDAR */
.calendar-section {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: white;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile p {
  margin: 2px 0;
}



.calendar {
  text-align: center;
  margin-top: 10px;
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  text-align: center;
  gap: 5px;
  margin-top: 5px;
}

.calendar-grid span {
  padding: 5px;
}

.today {
  font-weight: bold;
  color: blue;
  border-bottom: 2px solid blue;
}

@media (max-width: 1024px) {
  .profile-container {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 24px;
    gap: 20px !important;
      padding: 0 10px;

  }

  .profile-container .profile-overview {
    grid-column: 1fr;
      grid-column: span 1;

    margin-right: 0 !important;
  }
}

@media (max-width: 500px) {
  .stats {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
</style>
